@import 'standard';
@import 'small';
@import 'thin';
@import 'large';

.bannerSize, .bannerSize--large {
  @extend %bannerSize--standard;
}

.bannerSize, .bannerSize--standard, .bannerSize--large {
  @extend %bannerSize--standard;
}

.bannerWrapperSize--thin .bannerSize, .bannerSize--thin {
  @extend %bannerSize--thin;
}

.bannerWrapperSize--small .bannerSize, .bannerSize--small {
  @extend %bannerSize--small;
}

