.blogViewPanel {
  text-align: left;

  .container {
    //max-width: 1200px;
    margin: auto;
  }
  
}

.blogView {
  &__titleContainer {
    //max-width: 700px;
    margin: auto;
    text-align: left;
  }

}

.blogView__titleContainer {

  max-width: 960px;
}

.blogView__title {
  margin-top: 0.2rem;
  //font-weight: bold;
}

.blogView__date {
  font-size: 0.75rem;
  color: #999;
  display: inline-block;
  background: #eee;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.5rem;
}

.blogView__content {
  //max-width: 700px;
  margin: auto;
}
