.parallax {
  background-position-x: center;
}

.parallax__type2 {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  background-attachment : scroll;
  @include media-breakpoint-up(sm){
    background-attachment : fixed;
  }
}