.col-20p {
  flex: 0 0 20%;
  max-width: 20%;
}

@include media-breakpoint-up(sm) {
  .col-sm-20p {
    flex: 0 0 20%;
    max-width: 20%;
  }
    
}

.contentPanel.multiColumnPanel {
  .contentPanel {
    background-color: transparent;
  }
}