b,
strong {
  //font-weight: $weight-bold;
  font-weight: $weight-black;
}

a {
  color: $link-color;
  transition: $transition-base;

  &:hover,
  &:focus,
  &:active {
    //color: darken($primary-color, 5%);
    color: $link-hover-color;
    //text-decoration: none;
  }
}

h1,
h2,
h3 {
  //font-family: $family-header;
  //font-weight: $weight-normal;
  color: $header-color;
  line-height: 1.2;
  margin: 0.5em 0 0.3em;
}

h1,
h2,
h3 {
  font-family: $family-header;
}

h3,
h4,
h5 {
  //font-family: $family-header;
  b,
  strong {
    font-weight: $weight-black;
  }
}

h4 {
  color: $color-dark-gray;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 1px;
}

h5 {
  color: $color-dark-gray;
  font-weight: 400;
  line-height: 1.2;
}

h6{
  //font-family: $family-header2;
}

// size
h1 {
  font-size: 3.4rem;
  line-height: 1;
}

h2 {
  //font-size: 3rem;
  font-size: 2.5rem;
  line-height: 1;
  margin: 0.3em 0;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 0.8rem;
}

h6 {
  font-size: 1.1rem;
}

@include media-breakpoint-up(lg) {

  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 65px;
  }

  h3 {
    font-size: 50px;
  }

  h4 {
    font-size: 40px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    //font-size: 50px;
    font-size: 30px;
  }


}


// html editor
.text-tiny {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.5rem;
}