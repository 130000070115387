.htmlPanel {
  max-width: 960px;
  margin: auto;

  span.special-font {
    font-family: $family-header2;
    //font-size: 0.72em;
  }

  span.aos_effect_up {
    display: inline-block;
  }
}

div.imagePanel {
  position: relative;
}

div.imagePanel .htmlPanel {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  img {
    //max-width: 32%;
    max-width: 60%;

    @media (min-width: 456px) {
      //max-width: 26%;
      max-width: 60%;
    }

    @include media-breakpoint-up(sm) {
      // 576px and up
      //max-width: 18%;
      max-width: 60%;
    }

    @include media-breakpoint-up(md) {
      // 768px and
      //max-width: 17%;
      max-width: 60%;
    }

    @include media-breakpoint-up(lg) {
      // 992px and up
      //max-width: 20%;
      max-width: 100%;
    }

    // @include media-breakpoint-up(xl) { // 1200px and up
    //   max-width: 20%;
    // }
    @media (min-width: 1601px) {
      max-width: 100%;
    }
  }
}

div.imagePanel.from_the_streets .htmlPanel {
  h2 {
    @include media-breakpoint-down(sm) {
      font-size: 2.5rem;
    }
  }
}