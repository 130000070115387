.prdFilterSort {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  button {
    width: 100%;
  }
  .dropdown-menu {
    width: 100%;
    //transform: translate3d(0px, 38px, 0px) !important;
  }
}

.prdFilter {
  margin-bottom: 0.2rem;
  width: 100%;
}

.productSort {
  margin-bottom: 0.2rem;
  width: 100%;
}


@include media-breakpoint-up(lg) {/*>=992*/
  .prdFilterSort {
    button {
      width: auto;
    }
    .dropdown-menu {
      width: auto;
    }
  }
  .prdFilter {
    width: auto;
    min-width: 280px;
  }
  
  .productSort {
    width: auto;
    min-width: 250px;
  }
}