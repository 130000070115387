@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

@import "./fonts";
@import "./base";
@import "./sections";
@import "./form";

// top
@import "./layout/hamburger";
@import "./layout/top";
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";

//aos
@import "~aos/src/sass/aos";

// components
@import "../components";

// youtube banner size
@import "./modules/bannerSize/medium-video.scss";
.youtubePlayer--medium {
  @extend %bannerVideoSize--medium;
}
.youtubePlayer--thin {
  @extend %bannerSize--small;
}

@import "./site/index";

div.warranty_forms_age select.form-control {
  max-width: 314px !important;
}

body:not(.cmsedit) {
  div.cms_item.ig-feed-desktop {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.ig-feed-mobile {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.htmlPanel {
  .embedCodes--pinterest,
  .embedCodes--instagram,
  .embedCodes--instagramlink {
    display: flex;
    justify-content: center;
  }
}

section.social_youtube_short {
  .ytvideo {
    padding-bottom: unset;
    height: auto;
    iframe {
      height: 560px !important;
      width: 315px !important;
      position: static;
    }
  }
}

.contentPanel.contentPanel--darkgrey {
  background-color: #252525;
}

.youtubePanel .youtubePlayer--full,
.youtubePanel .youtubePlayer--standard {
  @media screen and (max-width: 576px) {
    height: unset;
    padding-top: 56.25%;
    iframe {
      width: 100% !important;
      height: 100% !important;
      top: 0 !important;
      left: 0 !important;
    }
  }
}

.bannerSize--standard {
  @include media-breakpoint-down(xs) {
    height: unset;
    padding-top: 56.25%;
  }
}

// .bannerSize--large{
//   @include media-breakpoint-down(xs){
//     height: unset;
//     padding-top: 56.25%;
//   }
// }

// div.cms_item.htmlPanel{
//   h1.spcial__text{
//     @include media-breakpoint-down(xs){
//       max-width: ;
//     }
//   }
// }

.read-more {
  background-color: #252525 !important;
  color: white !important;
}
.read-more-light {
  background-color: #ebebeb !important;
  color: #212529 !important;
}
.warranty-details {
  font-size: 0.7rem;
}
[data-bv-show="inline_rating"] .bv_main_container {
  justify-content: center;
}
p.homePageSpace {
  margin-bottom: 0;
  font-size: 0.1rem;
}
