html {
  font-size: 16px;

  @include media-breakpoint-up(lg) {
    font-size: 21px;
  }
}

body {
  //font-family: Verdana, Geneva, Tahoma, sans-serif;
  min-height: 100vh;
  background: #f4f4f4;
  font-weight: $weight-normal;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
