@import '../../../_scss/mixins';
@import '../../../_scss/variables';
@import "../../../_scss/bootstrap/bootstrap-override";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '../../../_scss/modules//buttons/skewButton.scss';

.linkTiles {
  margin: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;

  &.use__slider {
    display: block;
  }

  .slick-list {
    //border: 1px solid red;
    padding-top: 10% !important;
    padding-bottom: 10% !important;

    @include media-breakpoint-up(sm) {
      padding-top: 5% !important;
      padding-bottom: 5% !important;
    }

  }

  .slick-slide {
    transition: all 0.5s ease-in-out;
    //transform: scale(0.95);
    // &.small__big__img{
    //   transform: scale(1.15);
    //   z-index: 2; 
    // }
    // &[aria-hidden='true'] {
    //   opacity: 0;
    // }
  }

  .slick-center {
    //transform: scale(1.08);
    transform: scale(1.15) !important;
    z-index: 4 !important;
    @include media-breakpoint-up(sm){
      transform: scale(1.3) !important;
    }
    
  }
}

.linkTile {
  flex: 1 1 100%;
  padding: 2px;
  position: relative;

  @include media-breakpoint-up(sm) {
    flex: 1 1 50%;
  }

  @include media-breakpoint-up(lg) {
    flex: 1 1 25%;
  }
}

.linkTile:not(.use__slider){
  @include media-breakpoint-down(sm) {
    margin-top: 7px;
    margin-bottom: 7px;
  }
}

.linkTile__bg {
  width: 100%;
}

.linkTile__text {
  position: absolute;
  bottom: 6%;
  //bottom: 0%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  //background-color: rgba($color: #000000, $alpha: 0.7);
}

.linkTile__title {
  font-family: $family-header;
  //font-size: 2.4rem;
  font-size: 2.1rem;
  text-transform: uppercase;
  //font-weight: bold;
  line-height: 1;
  margin: 0.5rem 0.2rem;
  color: #fff;

  @include media-breakpoint-up(sm) {
    /*>=576*/
  }

  @include media-breakpoint-up(md) {
    /*>=768*/
  }

  @include media-breakpoint-up(lg) {
    /*>=992*/
  }

  @include media-breakpoint-up(xl) {
    /*>=1200*/
  }

  @include media-breakpoint-up(xxl) {
    //font-size: 2.6rem;
    font-size: 62.4px;
  }
}

.linkTile__link {
  @extend %skewButton;
  //font-size: 1.2rem;
  font-size: 1rem;
  font-weight: bold;

  &:hover,
  &:focus {
    color: white;
  }

}

.linkTile__tileLink {
  display: block;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(#fff, 0);
    transition: .4s all ease;
  }

  &:hover:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(#fff, 0.15);
    transition: .4s all ease;
  }
}