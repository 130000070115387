// @font-face {
//   font-family: 'Gotham';
//   src: url('/dist/fonts/Gotham-Bold.woff2') format('woff2'),
//       url('/dist/fonts/Gotham-Bold.woff') format('woff'),
//       url('/dist/fonts/Gotham-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Gotham';
//   src: url('/dist/fonts/Gotham-Medium.woff2') format('woff2'),
//       url('/dist/fonts/Gotham-Medium.woff') format('woff'),
//       url('/dist/fonts/Gotham-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Gotham';
//   src: url('/dist/fonts/Gotham-Book.woff2') format('woff2'),
//       url('/dist/fonts/Gotham-Book.woff') format('woff'),
//       url('/dist/fonts/Gotham-Book.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'DIN Next LT Pro';
//   src: url('/dist/fonts/DINNextLTPro-LightCondensed.woff2') format('woff2'),
//       url('/dist/fonts/DINNextLTPro-LightCondensed.woff') format('woff'),
//       url('/dist/fonts/DINNextLTPro-LightCondensed.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'DIN Next LT Pro';
//   src: url('/dist/fonts/DINNextLTPro-BoldCondensed.woff2') format('woff2'),
//       url('/dist/fonts/DINNextLTPro-BoldCondensed.woff') format('woff'),
//       url('/dist/fonts/DINNextLTPro-BoldCondensed.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

@font-face {
  font-display: swap;
  font-family: 'Bayon';
  src: url('/dist/fonts/bayon-v29-latin-regular.woff2') format('woff2'),
      url('/dist/fonts/bayon-v29-latin-regular.woff') format('woff'),
      url('/dist/fonts/bayon-v29-latin-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src: url('/dist/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
      url('/dist/fonts/roboto-v30-latin-regular.woff') format('woff'),
      url('/dist/fonts/roboto-v30-latin-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src: url('/dist/fonts/roboto-v30-latin-900.woff2') format('woff2'),
      url('/dist/fonts/roboto-v30-latin-900.woff') format('woff'),
      url('/dist/fonts/roboto-v30-latin-900.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Permanent Marker';
  src: url('/dist/fonts/permanent-marker-v16-latin-regular.woff2') format('woff2'),
      url('/dist/fonts/permanent-marker-v16-latin-regular.woff') format('woff'),
      url('/dist/fonts/permanent-marker-v16-latin-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}