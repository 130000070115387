$top-bar-z-index: 1000 !default;
$top-bar-height: (
  mobile: 2rem,
  desktop: 2rem) !default;

.topNav__logo {
  @extend %topNavMarginTop;
  position: absolute;
  //top: map-get($top-bar-height, mobile);
  top: 50%;
  left : 50%;
  transform: translate(-50%, -50%);
  z-index: $top-bar-z-index + 2;
img {
  width: 200px;
}

@include media-breakpoint-up(lg) {
  //top: map-get($top-bar-height, desktop);
  //top: 0;
  //left: 4rem;

  img {
    width: 300px;
  }
}

}

.topNav__skewBar {
  position: fixed;
  @extend %topNavMarginTop;
  background: linear-gradient(rgba(#fff, 0.2), rgba(#fff, 0));
  width: 100%;
  height: 100%;
  left: 110px;
  transform: skewX(-12deg) translateX(-100%);
  transform-origin: top left;
  z-index: $top-bar-z-index + 1;
  pointer-events: none;
  display: none;
  @include media-breakpoint-up(lg) {
    left: 330px;
  }
}